import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Josefin_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font\"}],\"variableName\":\"josefinSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/opt/buildhome/repo/src/components/Header/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/UI/Button/style.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/UI/LinkButton/style.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/UI/Loader/style.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/UI/ContentSection/style.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/UI/Input/style.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/Footer/style.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/globalStyles/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/src/store/StoreProvider.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/svg/starBg.svg");
